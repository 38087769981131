@use '@shared/util-styling/mixins';

$_spacing-size: 64px;

.sections-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-48);

  @include mixins.breakpoint('medium') {
    gap: 60px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);

  @include mixins.breakpoint('medium') {
    flex-direction: row;
  }

  &__title {
    margin-bottom: var(--spacing-24);
  }

  &__primary-content {
    flex-grow: 1;
    width: 100%;

    @include mixins.breakpoint('medium') {
      max-width: 50%;
    }
  }

  &__secondary-content {
    flex-grow: 1;
  }
}

.pagination-container {
  margin-top: var(--spacing-24);
}
