@use '@shared/util-styling/mixins';

.page-content {
  margin: var(--spacing-16) 0 var(--spacing-40) 0;

  @include mixins.breakpoint('medium') {
    margin-bottom: var(--spacing-48);
  }
}

.back-button-container {
  margin-bottom: var(--spacing-16);
}
