@use '@shared/util-styling/mixins';

.search-form-container {
  padding: var(--spacing-24);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);

  &__address-fields {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);

    @include mixins.breakpoint('medium') {
      flex-direction: row;
    }
  }

  &__address-fields-item {
    flex-basis: 100%;
    flex-shrink: 1;
  }

  &__button {
    width: 100%;
  }
}
