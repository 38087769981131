@use '@shared/util-styling/tokens';

.button {
  &__icon {
    background: tokens.$mdrt-buttons-primary-bg-fill;
    border-radius: 50%;
    color: tokens.$mdrt-chips-action-chips-text-fill-high-emphasis;
    height: 24px;
    padding: var(--spacing-4);
    width: 24px;
  }
}
