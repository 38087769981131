@use '@shared/util-styling/mixins';

.card {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
  justify-content: center;
  padding: var(--spacing-32);

  &__message-title {
    font: var(--mdrt-headline-3);
  }

  &__message {
    font: var(--mdrt-title-3);
    text-align: center;
  }
}
