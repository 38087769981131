@use '@shared/util-styling/mixins';

.interests-modal-content {
  margin-inline: calc(-1 * var(--spacing-8));
  max-width: 676px;

  @include mixins.breakpoint('small') {
    margin-inline: 0;
  }
}

.interests-modal-controls {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
  justify-content: flex-end;
  padding-top: var(--spacing-8);

  @include mixins.breakpoint('small') {
    display: none;
  }

  &--secondary-layout {
    display: none;
    @include mixins.breakpoint('small') {
      display: flex;
      flex-direction: row;
    }
  }
}

.interests-modal-header {
  border-bottom: 0;
  flex-direction: column;
  gap: var(--spacing-4);
  padding: var(--spacing-24) var(--spacing-16) 0;
  text-align: center;

  h2 {
    font: var(--mdrt-headline-3);
  }

  p {
    color: var(--mdrt-color-text-on-light-secondary);
    font: var(--mdrt-body-2);
  }

  @include mixins.breakpoint('small') {
    padding-block: var(--spacing-32) var(--spacing-8);
  }
}

.interests-modal-footer {
  border-top: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
  padding: var(--spacing-8) var(--spacing-16) var(--spacing-16);

  @include mixins.breakpoint('small') {
    border-top: 0;
    padding: var(--spacing-8) var(--spacing-24) var(--spacing-24);
  }
}
